import React from "react"
import { FaFacebookF, FaInstagram } from "react-icons/fa"

// Styles
import styles from "../styles/components/footer.module.scss"

// Images
import nugoLogo from "../images/nugo-logo.svg"

const Footer = () => {
  const date = new Date()
  return (
    <footer className={styles.footer}>
      <div className={styles.social}>
        <a
          href="https://www.facebook.com/Lifetime-Fitness-422919781093919/"
          target="blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.instagram.com/lifetime.fitness.by.maduranga/"
          target="blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </a>
      </div>
      <p>
        Copyright {date.getFullYear()} Lifetime Fitness. All Rights Reserved.
      </p>
      <a
        className={styles.nugo}
        href="https://www.nugo.xyz"
        target="blank"
        rel="noopener noreferrer"
      >
        <img src={nugoLogo} alt="nugo" className={styles.desktop} />
      </a>

      <div className={styles.mobile}>
        <a
          href="https://www.facebook.com/Lifetime-Fitness-422919781093919/"
          target="blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.instagram.com/lifetime.fitness.by.maduranga/"
          target="blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </a>
      </div>
    </footer>
  )
}

export default Footer
