import React from "react"
import { Logo } from "./logo"
import { MobileLogo } from "./logoMobile"
import { Link } from "gatsby"
import NavDrawer from "./navdrawer"
// Styles
import styles from "../styles/components/header.module.scss"

class Header extends React.Component {
  state = {
    fixed: false,
    offset: 0,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    window.addEventListener("resize", this.handleResize)
  }

  handleResize = e => {
    if (e.target.innerWidth > 1040) {
      this.setState({
        fixed: false,
      })
    }
  }

  handleScroll = e => {
    if (window.innerWidth > 1040) {
      return
    }
    const offset = window.pageYOffset
    if (this.state.offset > offset) {
      this.setState({
        fixed: true,
      })
    } else {
      this.setState({
        fixed: false,
      })
    }
    this.setState({
      offset,
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
    window.addEventListener("resize", this.handleResize)
  }

  render() {
    return (
      <header>
        <ul
          className={styles.navbar}
          style={
            this.state.fixed ? { position: "fixed" } : { position: "absolute" }
          }
        >
          <div className={styles.logo}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <Link to="/">
            <div className={styles.mobileLogo}>
              <MobileLogo />
            </div>
          </Link>
          <div className={styles.navdrawer}>
            <NavDrawer />
          </div>
          <div className={styles.links}>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#team">Trainers</a>
            </li>
            <li>
              <a href="#pricing">Pricing</a>
            </li>
            <li>
              <a href="#gallery">Gallery</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
            {/* <li>
              <a href="/reservations">Reservations</a>
            </li> */}
          </div>
        </ul>
      </header>
    )
  }
}

export default Header
